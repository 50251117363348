<template>
  <div class="main-wrap">
    <page-bread />
    <el-container class="home-container">
      <el-aside width="292px">
        <page-aside>
          <el-menu :default-active="activeIndex" router>
            <el-menu-item
              :index="item.path"
              v-for="item in menuList"
              :key="item.path"
              >{{ item.name }}</el-menu-item
            >
          </el-menu>
        </page-aside>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import pageAside from "@/components/pageAside.vue";
import PageBread from "../../components/pageBread.vue";
export default {
  name: "",
  data() {
    return {
      menuList: [
        {
          name: "工作报告",
          path: "/information/workReport",
        },
        {
          name: "审计报告",
          path: "/information/auditReport",
        },
        {
          name: "工作简报",
          path: "/information/workBriefing",
        },
        {
          name: "业务信息",
          path: "/information/serviceInformation",
        }
      ],
    };
  },
  computed: {
    activeIndex:function () {
      return this.menuList.find(item => this.$route.path.includes(item.path)).path
    }
  },
  mounted() {},
  methods: {},
  components: {
    pageAside,
    PageBread,
  }
};
</script>

<style lang='scss' scoped>
.el-main {
  border-left: 8px solid #f4f4f4;
  padding: 0;
}
</style>